import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NAMESPACE } from '../reducers/user';
import { IUser, IUserDataSummary, IUserShort, IUserState } from '@models';
import { DeviceService } from '@shared/services/device.service';

export const selectUser = createFeatureSelector<IUserState>(NAMESPACE);

export const selectIsLoading = createSelector(
  selectUser,
  (state: IUserState) => state.isLoading
);

export const selectAccount = createSelector(
  selectUser,
  (state: IUserState) => state.account
);

export const selectAccountId = createSelector(
  selectAccount,
  (state) => state?.Id
);
export const isSameUser = (user: IUserShort | IUser) =>
  createSelector(selectAccount, (account) => {
    if (!user) return false;
    // guest
    if (!account) return DeviceService.deviceId === user.UUID;
    return account.Id === user.UserId;
  });

export const selectIsKiosker = createSelector(
  selectAccount,
  (state) => state?.IsKiosker
);

export const selectIsAuthenticated = createSelector(
  selectAccountId,
  (id) => id !== undefined
);

export const selectIsGuest = createSelector(
  selectAccount,
  (account) => !account
);

export const selectIsAccountUpdateLoading = createSelector(
  selectUser,
  (state: IUserState) => state.isAccountUpdateLoading
);

export const selectSummary = createSelector(
  selectUser,
  (state: IUserState) => state.summary
);

export const selectIsAgreedToElua = createSelector(
  selectSummary,
  (summary: IUserDataSummary) => summary?.AgreedToElua || false
);

export const selectIsActiveGuest = createSelector(
  selectSummary,
  (summary: IUserDataSummary) => {
    if (!summary || !summary.IsGuest) return false;
    if (
      summary.Cigars > 0 ||
      summary.Humidors > 0 ||
      summary.Reviews > 0 ||
      summary.Notes > 0 ||
      summary.SocialPosts > 0 ||
      summary.SocialComments > 0
    ) {
      return true;
    }
    return false;
  }
);

export const selectSettings = createSelector(
  selectUser,
  (state: IUserState) => state.settings
);

export const selectShareLocation = createSelector(
  selectUser,
  (state: IUserState) => !!state.settings?.ShareLocation
);

export const selectAuthenticatedIuser = createSelector(
  selectUser,
  selectSummary,
  selectSettings,
  (state, summary, settings) => {
    const {
      UserId,
      UUID,
      Cigars,
      // CigarsInJournal,
      // CigarsInFavorites,
      // CigarsInWishlist,
      // Humidors,
      // Reviews,
      // Notes,
      // Scans,
      SocialPosts,
      SocialComments,
      SocialLikes,
      Followers,
      Following,
    } = summary || {};

    return {
      ...state.account,
      UserId,
      UUID,
      Cigars,
      CigarsInJournal: 0,
      CigarsInFavorites: 0,
      CigarsInWishlist: 0,
      Humidors: 0,
      Reviews: 0,
      Notes: 0,
      Scans: 0,
      SocialPosts,
      SocialComments,
      SocialLikes,
      Followers,
      Following,
      Blocked: false,
      BlockedOn: null,
      Followed: false,
      FollowedOn: null,
      HumidorsValue: null,
      CigarsInHumidors: 0,
    } as IUser;
  }
);
