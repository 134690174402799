import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { DeviceService } from '@shared/services/device.service';

import { Device, DeviceInfo } from '@capacitor/device';
import debug from 'debug';
import { IState } from '@models';
import { Store } from '@ngrx/store';

declare var APP_VERSION: string;

const log = debug('cs:ErrorService');

declare var APP_VERSION: string;

interface Error {
  Message: string;
  StackTrace: string;
  Platform: string;
  UUID: string;
  UserId: any;
  DeviceManufacturer: string;
  DeviceModel: string;
  Name: string;
  Version: string;
}

@Injectable({ providedIn: 'root' })
export class ErrorService {
  static userId = null;
  constructor(
    private http: HttpClient,
    private platformService: Platform,
    private deviceService: DeviceService
  ) {}

  async postError(message: string, StackTrace: string) {
    const device = await Device.getInfo();

    log('ErrorService', message);
    return firstValueFrom(
      this.http.post<void>('errors', {
        Message: JSON.stringify({
          message: message,
          url: document.location.href,
        }),
        StackTrace,
        Platform: this.platformService.platforms().toString(),
        UUID: this.deviceService.getDeviceID(),
        UserId: ErrorService.userId,
        DeviceManufacturer: device && device.manufacturer,
        DeviceModel: device && device.model,
        Name: 'CSWeb',
        Version: APP_VERSION,
      })
    );
  }
}
