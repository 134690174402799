import { IUserState } from '@models';
import { createReducer, on } from '@ngrx/store';
import { ErrorService } from '@services/error.service';
import * as userActions from '@store/actions/user';

export const NAMESPACE = 'user';

export const initialState: IUserState = {
  summary: null,
  settings: null,
  account: null,
  isAccountUpdateLoading: false,
  isLoading: false,
};

export const UserReducer = createReducer(
  initialState,
  on(
    userActions.SummaryRequest,
    userActions.AgreeToTermsRequest,
    userActions.SettingsRequest,
    userActions.SettingsUpdateRequest,
    userActions.LogoutRequest,
    userActions.UserDeleteRequest,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(userActions.AccountRequest, (state) => ({
    ...state,
    isAccountUpdateLoading: true,
  })),
  on(userActions.AccountSuccess, (state, { account }) => {
    // this is a hack to update the userId in ErrorService
    // ErrorHandler cannot import the Store as StoreDevtoolsModule already imports it
    // it creates a circular dependency
    ErrorService.userId = account?.Id; // Update userId in ErrorService
    return {
      ...state,
      account,
      isAccountUpdateLoading: false,
    };
  }),
  on(userActions.SummarySuccess, (state, { summary }) => ({
    ...state,
    summary,
    isLoading: false,
  })),
  on(userActions.AgreeToTermsSuccess, (state) => ({
    ...state,
    summary: {
      ...state.summary,
      AgreedToElua: true,
    },
    isLoading: false,
  })),
  on(
    userActions.SettingsSuccess,
    userActions.SettingsUpdateSuccess,
    (state, { settings }) => ({
      ...state,
      settings,
      isLoading: false,
    })
  ),
  on(
    userActions.UserError,
    userActions.LogoutSuccess,
    userActions.UserDeleteSuccess,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(userActions.AccountUpdateError, (state) => ({
    ...state,
    isAccountUpdateLoading: false,
  })),

  on(userActions.LogoutSuccess, userActions.LoginSuccess, () => initialState)
);

export default UserReducer;
